<template>
<div class="container" style="margin: 50px;">
  <div class="row">
    <div class="col-md-3">

    </div>
    <div class="col-md-6">
      <form>
        <div class="form-group">
          <label for="exampleInputEmail1">Email address</label>
          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
          <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Password</label>
          <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="exampleCheck1">
          <label class="form-check-label" for="exampleCheck1">Remember</label>
          <button type="submit" class="btn btn-primary" >Submit</button>
        </div>
      </form>
    </div>
    <div class="col-md-3">

    </div>
  </div>
</div>
</template>

<script>
export default {
name: "p_workplace"
}
</script>

<style scoped>

</style>
